<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <!--<span class="modal__header__subtitle text--capital">
              Refinance of Loan Account
            </span>-->
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                >
                  cancel
                </button>
                <button
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                  :disabled="process"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#addDocumentModal"
              class="modal__close modal__close--black"
              ref="close"
              @click="closeModal"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Upload Document</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="mb5">
              <div class="form__item">
                <label class="form__label">Title</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter Document title"
                  v-model="form.title"
                />
                <div class="error--text" v-if="!uiProps.errors.title.valid">
                  {{ uiProps.errors.title.message }}
                </div>
              </div>
              <div class="form__item">
                <label class="form__label text--regular text--black"
                  >Description</label
                >
                <div
                  class="error--text"
                  v-if="!uiProps.errors.description.valid"
                >
                  {{ uiProps.errors.description.message }}
                </div>
                <textarea
                  cols="5"
                  rows="2"
                  class="input form__input"
                  placeholder="Enter description"
                  v-model="form.description"
                ></textarea>
              </div>
              <div
                class="form__item form__item_dottedbg bg"
                ref="uploadArea"
                @drop="onDropEvent"
              >
                <div v-if="!uiProps.uploadSuccess" class="p-3 text--center">
                  <span
                    class="d-block text--regular-alt text--600 text--black my-2"
                    >File Upload</span
                  >
                  <span class="d-block text--regular text--500 text--black my-2"
                    >Click or drop file to upload</span
                  >
                  <div style="visibility: hidden">
                    <input
                      type="file"
                      ref="uploadInput"
                      :name="uiProps.file"
                      :disabled="process"
                      @change="
                        filesChange($event.target.name, $event.target.files);
                        fileCount = $event.target.files.length;
                      "
                      accept="image/*,.pdf"
                      class="input-file"
                    />
                  </div>
                  <button
                    class="
                      my-2
                      button button--black
                      text--500 text--white text--small text--capital
                    "
                    @click.prevent="triggerUpload()"
                  >
                    Choose File
                  </button>
                </div>
                <div v-else class="text-center p-3">
                  <h4>File attached successfully.</h4>
                  <p>
                    <a
                      href="javascript:void(0)"
                      @click="uiProps.uploadSuccess = false"
                      >Change File</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="form__item">
              <div class="form__action">
                <button
                  type="button"
                  class="button form__button form__button--lg save-changes"
                  @click="confirmSave()"
                >
                  {{ process ? "Processing..." : "Save" }}
                </button>
                <a
                  role="button"
                  data-dismiss="#addDocumentModal"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="closeModal"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepositApiService from "@/core/services/api.service";

function initialState() {
  return {
    form: { description: "", title: "", file: null },
    uiProps: {
      errors: {
        description: { valid: true, message: "" },
        title: { valid: true, message: "" },
      },
      file: "file",
      uploadSuccess: false,
    },
    confirm: false,
    process: false,
  };
}
export default {
  name: "addAttachmentsModal",
  props: {
    account: Object,
    show: Boolean,
  },
  data: function () {
    return initialState();
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmSave() {
      this.resetErrors();
      if (this.validate()) {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },
    resetWindow: function (){
        Object.assign(this.$data, initialState());
    },
    save: function () {
      this.process = true;
      const formData = new FormData();
      const loanKey = this.$route.params.loanAccountKey;
      formData.append("file", this.file);
      formData.append("description", this.form.description);
      DepositApiService.post(
        `GeneralDocument/CreateAttachment/${this.account.id}?accountType=LoanAccount&documentTitle=${this.form.title}&loanKey=${loanKey}`,
        formData
      )
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("reload-activities");
            this.$emit("alertSuccess", "File attached successfully");
            this.$refs.close.click();
            this.resetWindow();
          } else {
            this.$emit(
              "alertError",
              res.message.length
                ? res.message
                : "Unable to save Attachment currently"
            );
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          try {
            this.$emit(
              "alertError",
              e.response.data.message
                ? e.response.data.message
                : "Unable to save Comment currently"
            );
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    validate: function () {
      if (this.form.title.length < 3) {
        this.uiProps.errors.title.valid = false;
        this.uiProps.errors.title.message =
          "Title cannot be less than 3 characters";
        return false;
      } else if (this.form.description.length < 5) {
        this.uiProps.errors.description.valid = false;
        this.uiProps.errors.description.message =
          "Description cannot be less than 5 characters";
        return false;
      } else {
        return true;
      }
    },
    filesChange(fieldName, fileList) {
      this.$refs.uploadArea.files = fileList;
      this.file = fileList[0];
      // handle file changes
      // const formData = new FormData();
      //
      // if (!fileList.length) return;
      //
      // // append the files to FormData
      // Array.from(Array(fileList.length).keys()).map((x) => {
      //   formData.append(fieldName, fileList[x], fileList[x].name);
      // });
      this.uiProps.uploadSuccess = true;

      // save it
      // this.save(formData);
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
    triggerUpload() {
      this.$refs.uploadInput.click();
    },
    highlight() {
      this.$refs.uploadArea.classList.add("bg-secondary");
    },
    unhighlight() {
      this.$refs.uploadArea.classList.remove("bg-secondary");
    },
    onDropEvent(e) {
      e.preventDefault();
      this.$refs.uploadArea.files = e.dataTransfer.files;
      this.file = e.dataTransfer.files[0];
      this.unhighlight();
      this.uiProps.uploadSuccess = true;
    },
    listenForDropDragUpload() {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
      ].forEach(
        function (evt) {
          this.$refs.uploadArea.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
              if (evt === "dragenter" || evt === "dragover") {
                this.highlight();
              } else if (evt === "dragleave") {
                this.unhighlight();
              }
            }.bind(this),
            false
          );
        }.bind(this)
      );
    },
  },
  mounted() {
    // this.listenForDropDragUpload();
  },
};
</script>
