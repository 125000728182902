<template>
  <div
    class="tab-pane fade"
    id="pills-attachments"
    role="tabpanel"
    aria-labelledby="pills-attachments-tab"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Delete Attachment
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="deleteAttachment()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3" v-if="userCanUploadAttachment">
      <button
        class="button button--black d-flex align-items-center ml-auto"
        @click="toggleAttachModal"
      >
        <span class="text--capital d-inline-flex mr-2 text-white text--small"
          >upload new attachment</span
        >
        <img src="assets/img/upload.svg" alt="" srcset="" />
      </button>
    </div>
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="loading"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--600 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>
    <table class="customtable w-100">
      <thead>
        <tr>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 20%"
          >
            Document
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            File Type
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Size
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Last Modified
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 20%"
          >
            Added By
          </th>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 15%"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody v-if="data && !loading">
        <tr v-for="(item, i) in data" :key="i">
          <td>
            <span
              class="
                table__body__text table__text
                text--capital
                d-inline-flex
                align-items-center
              "
            >
              <img src="assets/img/paper.svg" alt="" srcset="" class="mr-2" />
              {{ item.document_title }}
            </span>
          </td>
          <td>
            <span class="table__body__text table__text text--capital">{{
              item.document_type
            }}</span>
          </td>
          <td>
            <span class="table__body__text table__text text--capital"
              >{{ item.document_file_size | kb }}</span
            >
          </td>
          <td>
            <span class="table__body__text table__text text--capital">{{
              item.last_modified_date | moment
            }}</span>
          </td>
          <td>
            <span class="table__body__text table__text text--capital">{{
              item.created_by
            }}</span>
          </td>
          <td>
            <div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                action
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item text--capital"
                  data-modal="#previewModal"
                  @click="previewFile(item.document_key)"
                  >Preview</a
                >
                <a
                  class="dropdown-item text--capital"
                  @click="downloadFile(item.document_key)"
                  >Download</a
                >
                <a
                  class="dropdown-item text--capital"
                  data-modal="#deleteDocumentModal"
                  @click="confirmSave(item.document_key)"
                  v-if="userCanDeleteAttachment"
                  >Delete</a
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "@/core/services/api.service";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
export default {
  name: "accountAttachmentsTab",
  props: {
    data: Array,
    enums: Object,
    loading: Boolean,
  },
  data() {
    return {
      attachmentName: "",
      attachmentType: "",
      confirm: false,
      process: false,
      key: "",
    };
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
    kb(val) {
      const sizes = ['bytes', 'KB', 'MB', 'GB'];
      if (val == 0) return '';
      const i = parseInt(Math.floor(Math.log(val) / Math.log(1024)), 10);
      if (i == 0) return `${val} ${sizes[i]}`
      return `${(val / (1024 ** i) ).toFixed(1)} ${sizes[i]}`;
    },
  },
  methods: {
    toggleAttachModal() {
      this.$emit('toggle-attachment-modal');
    },
    getAttachmentName(key) {
      this.attachmentName = "";
      ApiService.get(
        `GeneralDocument/getUploadedDocumentByDocumentkey?documentKey=${key}`
      )
        .then((response) => {
          this.attachmentName = response.data.data.document_original_file_name;
          this.attachmentType = response.data.data.document_type;
        })
        .catch((error) => {
          this.$emit("download-error", error);
          // this.alertError(error.response.data.message);
        });
    },
    confirmSave(key) {
      this.key = key;
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    async downloadFile(key) {
      const loanKey = this.$route.params.loanAccountKey;
      await this.getAttachmentName(key);
      ApiService.getFormData(
        `GeneralDocument/DownloadDocument?documentKey=${key}&loanKey=${loanKey}`
      )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.attachmentName || "file");
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          this.$emit("download-error", error);
          // this.alertError(error.response.data.message)
        });
    },
    previewFile(key) {
      const loanKey = this.$route.params.loanAccountKey;
      this.$emit("preview-modal", key, loanKey);
    },
    deleteAttachment() {
      this.process = true;
      const loanKey = this.$route.params.loanAccountKey;
      ApiService.delete(
        `GeneralDocument/delete?documentKey=${this.key}&loanKey=${loanKey}`
      )
        .then((response) => {
          this.process = false;
          this.confirm = false;
          this.$emit("reload-attachments");
          this.$emit("reload-activities");
          this.$emit("delete-successful", response.data.message);
        })
        .catch((error) => {
          this.process = false;
          this.confirm = false;
          this.$emit("download-error", error.response.data.message);
          this.$emit("reload-attachments");
        });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanUploadAttachment() {
      const document = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = document;
      return permissionsList.CanCreateDocuments;
    },
    userCanEditAttachment() {
      const document = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = document;
      return permissionsList.CanEditDocuments;
    },
    userCanDeleteAttachment() {
      const document = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = document;
      return permissionsList.CanDeleteDocuments;
    },
  }
};
</script>
<style lang="scss" scoped>
  .customtable tbody td {
    padding-right: 15px !important;
  }
</style>
