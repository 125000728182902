<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm show"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog modal__dialog_alert" role="document">
        <!-- <transition name="slide" appear> -->
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                ref="close"
                @click="closeModal"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Unlock Account
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Confirm that you want to unlock this account?
              </h5>
              <p>
                All suspended activities will be unlocked and automated
                transactions will resume after this action.
              </p>

              <div class="d-flex mt-5">
                <!-- <button
                    class="button button--lg button--cancel modal__confirm__button--delete mr-2"
                    @click="close()"
                  >
                    cancel
                  </button>                -->

                <button
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Ulocking..." : "Unlock Account" }}
                </button>
              </div>
            </div>
          </div>
        <!-- </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
function initialState() {
  return {
    form: { comment: "" },
    uiProps: {
      errors: {
        comment: { valid: true, message: "" },
      },
    },
    confirm: true,
    process: false,
  };
}
export default {
  name: "unLockAccountModal",
  props: {
    account: Object,
    show: Boolean,
  },
  data: function () {
    return initialState();
  },
  methods: {
    close() {
      this.show = false;
    },
    save: function () {
      this.process = true;
      LoanApiService.patch(`LoanAccount/unlock-loan-account/${this.account.id}`)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("get-activities");
            this.$emit("alertSuccess", "Account Unlocked Successfully");
            this.$refs.close.click();
          } else {
            this.$emit(
              "alertError",
              res.message ? res.message : "Unable to unlock account currently"
            );
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          try {
            this.$emit(
              "alertError",
              e.response.data.message
                ? e.response.data.message
                : "Unable to unlock account currently"
            );
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    validate: function () {
      if (this.form.comment.length < 5) {
        this.uiProps.errors.comment.valid = false;
        this.uiProps.errors.comment.message =
          "Comment cannot be less than 5 characters";
        return false;
      } else {
        return true;
      }
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
    closeModal() {
      this.$emit('close-unlock-modal')
    }
  },
};
</script>
