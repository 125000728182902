<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Lock Account
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#lockAccountModal"
                class="modal__close modal__close--black"
                ref="close"
                @click="closeModal"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Lock Account
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <span class="d-block text--black text--600 text--regular mb-4">
                  Activities to Suspend
                </span>
                <label
                  for="applyInterest"
                  class="d-block has-checkbox text--black text--600 text--regular"
                  >Applying Interest
                  <input type="checkbox" id="applyInterest" class="form-" />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form__item">
                <div class="form__item">
                  <label class="form__label">comments</label>
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder=""
                    v-model="form.comment"
                  ></textarea>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    @click="confirmSave()"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                  >
                    {{ process ? "Processing..." : "Lock Account" }}
                  </button>
                  <a
                    data-dismiss="#lockAccountModal"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="closeModal"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
function initialState() {
  return {
    form: { comment: "" },
    uiProps: {
      errors: {
        comment: { valid: true, message: "" },
      },
    },
    confirm: false,
    process: false,
  };
}
export default {
  name: "lockAccountModal",
  props: {
    account: Object,
    show: Boolean,
  },
  data: function () {
    return initialState();
  },
  methods: {
    confirmSave() {
      this.resetErrors();
      // eslint-disable-next-line no-constant-condition
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    save: function () {
      this.process = true;
      LoanApiService.patch(`LoanAccount/lock-loan-account/${this.account.id}`)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("get-activities");
            this.$emit("alertSuccess", "Account Locked Successfully");
            this.$refs.close.click();
          } else {
            this.$emit(
              "alertError",
              res.message ? res.message : "Unable to lock account currently"
            );
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          try {
            this.$emit(
              "alertError",
              e.response.data.message
                ? e.response.data.message
                : "Unable to lock account currently"
            );
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    validate: function () {
      if (this.form.comment.length < 5) {
        this.uiProps.errors.comment.valid = false;
        this.uiProps.errors.comment.message =
          "Comment cannot be less than 5 characters";
        return false;
      } else {
        return true;
      }
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
    closeModal() {
      this.$emit('close-lock-modal');
    }
  },
};
</script>
