<template>
  <div
    class="modal modal__right"
    id="editSecurity"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Edit Securities
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#editSecurity"
              ref="close"
              class="modal__close modal__close--black"
              @click="$emit('close')"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Edit Securities</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="mt-3">
              <div class="row py-3 align-items-center">
                <div class="col-6">
                  <span
                    class="
                      text--black text--regular
                      d-block
                      text--500
                      mb-3
                      text--capital
                    "
                  >
                    Account Recipient
                  </span>
                  <span
                    class="
                      text--black text--medium
                      d-block
                      text--600 text--capital
                    "
                  >
                    {{ customerFullName }}
                  </span>
                </div>
                <div class="col-6">
                  <span
                    class="
                      text--black text--regular
                      d-block
                      text--500
                      mb-3
                      text--capital
                    "
                  >
                    Display Name
                  </span>
                  <span
                    class="
                      text--black text--medium
                      d-block
                      text--600 text--capital
                    "
                  >
                    {{ displayName }}
                  </span>
                </div>
              </div>
            </div>
            <ul id="accordion" class="mb5">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#guarantors"
                  aria-expanded="false"
                  aria-controls="guarantors"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Guarantors
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="guarantors" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div
                      class="p-5 border-grey border-radius mt-3"
                      v-for="(item, index) in security"
                      :key="index"
                    >
                      <div class="form__item" v-if="!security.length">
                        <label class="form__label">Select Source</label>
                        <!-- <select class="select select--lg" required>
                          <option value="" disabled selected>
                            Select Source
                          </option>
                          <option value="">Loan Balance</option>
                          <option value="">Account Balance</option>
                        </select> -->
                        <template>
                          <multiselect
                            label="customerName"
                            track-by="customerKey"
                            placeholder="Type to search for client by name"
                            open-direction="bottom"
                            :options="item.sourceList"
                            :value="item.source"
                            :multiple="false"
                            :searchable="true"
                            :loading="item.loadingSource"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="searchCustomers($event, index)"
                            @select="
                              updateGuarantorField($event, index, 'source')
                            "
                          >
                            <template slot="tag" slot-scope="{ option }"
                              ><span class="custom__tag"
                                ><span>{{ option.customerName }}</span></span
                              >
                            </template>
                            <span slot="noResult">Oops! No result found.</span>
                          </multiselect>
                        </template>
                      </div>
                      <div class="form__item" v-else>
                        <label class="form__label">Select Source</label>
                        <select
                          class="select select--lg"
                          required
                          v-model="item.guarantorKey"
                          @change="
                            updatedGuarantorField(item.guarantorKey, index)
                          "
                        >
                          <option value="" disabled selected>
                            Select Source
                          </option>
                          <option
                            v-for="(account, index) in allCustomers"
                            :key="index"
                            :value="account.customerKey"
                          >
                            {{ account.customerFirstName }}
                            {{ account.customerLastName }}
                          </option>
                        </select>
                        <!-- <template>
                          <multiselect
                            label="customerFirstName"
                            track-by="customerKey"
                            placeholder="Type to search for client by name"
                            open-direction="bottom"
                            :options="allCustomers"
                            :value="item.source"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @select="
                              updatedGuarantorField($event, index, 'source')
                            "
                          >
                            <template slot="tag" slot-scope="{ option }"
                              ><span class="custom__tag"
                                ><span>{{ option.customerName }}</span></span
                              >
                            </template>
                            <span slot="noResult">Oops! No result found.</span>
                          </multiselect>
                        </template> -->
                      </div>
                      <div class="row">
                        <div class="col-6" v-if="!security.length">
                          <div class="form__item">
                            <label class="form__label">Select Account</label>
                            <select
                              class="select select--lg"
                              v-model="item.depositAccountKey"
                            >
                              <option value="" selected disabled>
                                __select an account__
                              </option>
                              <option
                                v-for="(account, index) in item.accountList"
                                :key="index"
                                :value="account.accountKey"
                              >
                                {{ account.accountName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6" v-else>
                          <div class="form__item">
                            <label class="form__label">Select Account</label>
                            <select
                              class="select select--lg"
                              v-model="item.depositAccountKey"
                              @change="updateSavingsKey(index)"
                            >
                              <option value="" selected disabled>
                                __select an account__
                              </option>
                              <option
                                v-for="(
                                  account, index
                                ) in item.depositAccountByGuarantor ||
                                accountList"
                                :key="index"
                                :value="account.accountKey"
                                :selected="item.depositAccountKey"
                              >
                                {{ account.accountName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Amount</label>
                            <input
                              :value="item.amount"
                              type="text"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              @input="
                                updateGuarantorField($event, index, 'amount')
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-end justify-content-between">
                        <div class="px-4">
                          <button
                            type="button"
                            class="button button--cancel text--capital"
                            @click="deleteGuarantorForm(index)"
                          >
                            <span class="d-inline-flex">delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="text-right row mt-4 mb5">
                      <div class="col ml-auto">
                        <div>
                          <button
                            type="button"
                            class="
                              ml-5
                              button button--grey
                              text--black text--capital
                            "
                            @click="pushGuarantors"
                          >
                            <span>Add Guarantors</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#collaterals"
                  aria-expanded="false"
                  aria-controls="collaterals"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Collaterals
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="collaterals" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div
                      class="p-5 border-grey border-radius mt-3"
                      v-for="(item, index) in collateralAsset"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Name</label>
                            <input
                              type="text"
                              :value="item.assetName"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              @input="
                                updateCollateralInputField(
                                  $event,
                                  index,
                                  'assetName'
                                )
                              "
                            />
                          </div>
                        </div>
                        <!-- <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">description</label>
                            <input
                              type="text"
                              :value="item.description"
                              class="input input--right form__input form__input--lg"
                              @input="
                                updateCollateralInputField($event, index, 'description')
                              "
                            />
                          </div>
                        </div> -->
                        <div class="col-6">
                          <div class="form__item">
                            <label class="form__label">Amount</label>
                            <input
                              type="text"
                              :value="item.amount"
                              class="
                                input input--right
                                form__input form__input--lg
                              "
                              @input="
                                updateCollateralInputField(
                                  $event,
                                  index,
                                  'amount'
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-end justify-content-between">
                        <div class="px-4">
                          <button
                            type="button"
                            class="button button--cancel text--capital"
                            @click="deleteCollateralAssets(index)"
                          >
                            <span class="d-inline-flex">delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="text-right row mt-4 mb5">
                      <div class="col ml-auto">
                        <div>
                          <button
                            type="button"
                            class="
                              ml-5
                              button button--grey
                              text--black text--capital
                            "
                            @click="pushCollateralAssets"
                          >
                            <span>Add Collaterals</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>

            <div class="form__item">
              <div class="form__action">
                <button
                  data-dismiss="#editSecurity"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
                <a
                  data-dismiss="#editSecurity"
                  role="button"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
import DepositApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import Multiselect from "vue-multiselect";
function initialState() {
  return {
    form: { comment: "" },
    uiProps: {
      errors: {
        comment: { valid: true, message: "" },
      },
    },
    security: [],
    collateralAsset: [],
    confirm: false,
    process: false,
    fetchCustomer: {
      pageIndex: 1,
      pageSize: 10000000,
    },
    allCustomers: [],
  };
}
export default {
  name: "EditSecurityModal",
  props: {
    account: Object,
    show: Boolean,
  },
  components: {
    Multiselect,
  },
  data: function () {
    return initialState();
  },
  methods: {
    confirmSave() {
      this.resetErrors();
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    getAllCustomers() {
      GeneralApiService.post(
        "Customer/customers",
        this.fetchCustomer
      )
        .then((res) => res.data.data.items)
        .then((res) => {
          this.allCustomers = res;
        });
    },
    searchCustomers(search, index) {
      const self = this;
      if (search.length) {
        self.security[index].loadingSource = true;
        GeneralApiService.get(
          "Customer/search/" +
            search
        )
          .then((res) => res.data.data.items)
          .then((res) => {
            self.security[index].loadingSource = false;
            self.security[index].sourceList = res;
          })
          .catch(() => {
            self.security[index].loadingSource = false;
          });
      }
    },
    searchCustomer(search, index) {
      const self = this;
      if (search.length) {
        self.security[index].loadingSource = true;
        GeneralApiService.get(
          "Customer/search/" +
            search
        )
          .then((res) => res.data.data.items)
          .then((res) => {
            self.allCustomers = res;
          });
      }
    },
    save: function () {
      this.process = true;
      let newCollateralAssets = [];
      let newGuarantorListing = [];
      let copyOfPayload = JSON.parse(JSON.stringify(this.$data));
      const guarantor = copyOfPayload.security;
      const guarantorListing = guarantor.map((e) => e.actualObject);
      newGuarantorListing = Array.prototype.concat.apply([], guarantorListing);
      const collateralAssets = copyOfPayload.collateralAsset;
      const collateralAssetsListing = collateralAssets.map(
        (e) => e.actualObject
      );
      newCollateralAssets = Array.prototype.concat.apply(
        [],
        collateralAssetsListing
      );
      const loanAccountKey = this.account.id;
      const loanAmount = this.account.loanAmount;
      const loanProductKey = this.account.adminLoanProduct.loan_product_key;
      copyOfPayload.security = [...newGuarantorListing, ...newCollateralAssets];
      let mergedData = {
        ...copyOfPayload,
        loanAccountKey: loanAccountKey,
        loanAmount: loanAmount,
        loanProductKey: loanProductKey,
      };
      delete mergedData.collateralAsset;
      delete mergedData.uiProps;
      delete mergedData.form;
      delete mergedData.confirm;
      delete mergedData.process;
      delete mergedData.fetchCustomer;
      delete mergedData.allCustomers;

      // if (!this.account.guarantors.length || !this.account.assets.length) {
      //   LoanApiService.post("LoanAccount/addloanaccountsecurity", mergedData)
      //   .then((res) => res.data)
      //   .then((res) => {
      //     this.confirm = false;
      //     this.process = false;
      //     if (res.status) {
      //       this.$emit("refresh");
      //       this.$emit("alertSuccess", res.message);
      //       this.$refs.close.click();
      //       // this.initialState();
      //     }
      //   })
      //   .catch((e) => {
      //     this.confirm = false;
      //     this.process = false;
      //     this.$emit(
      //       "alertError",
      //       e.response.data.message
      //         ? e.response.data.message
      //         : "Unable to edit securities currently"
      //     );
      //   });
      // } else {
      LoanApiService.patch(
        `LoanAccount/editloanaccountsecurity`,
        mergedData
      )
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("alertSuccess", res.message);
            this.$refs.close.click();
            // this.initialState();
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          this.$emit(
            "alertError",
            e.response.data.message
              ? e.response.data.message
              : "Unable to edit securities currently"
          );
        });
      // }
    },

    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
    pushGuarantors() {
      this.security.push({
        source: "",
        amount: "",
        account: "",
        accountList: [],
        depositAccountByGuarantor: [],
        sourceList: [],
        loadingSource: false,
      });
    },
    deleteGuarantorForm(index) {
      this.security.splice(index, 1);
    },
    pushCollateralAssets() {
      this.collateralAsset.push({
        assetName: "",
        description: "",
        amount: "",
      });
    },
    updateSecurity() {
      if (this.account.assets.length) {
        // this.collateralAsset = this.account.assets;
        const collateral = this.account.assets;
        this.collateralAsset = collateral.map((item) => ({
          ...item,
          actualObject: {
            amount: item.amount,
            assetName: item.assetName,
            description: "",
            guarantorKey: "",
            guarantorType: "ASSET",
            type: "ASSET",
            savingsAccountKey: "",
            guarantyKey: item.guarantyKey,
          },
        }));
      }
      if (this.account.guarantors.length) {
        const securities = this.account.guarantors;
        this.security = securities.map((item) => ({
          ...item,
          depositAccountByGuarantor: item.depositAccountByGuarantor.data,
          actualObject: {
            savingsAccountKey: item.depositAccountKey,
            type: "GUARANTOR",
            guarantorKey: item.guarantorKey,
            guarantorType: "GUARANTOR",
            amount: item.amount,
            description: "",
            assetName: "",
            guarantyKey: item.guarantyKey,
          },
        }));
      }
    },
    deleteCollateralAssets(index) {
      this.collateralAsset.splice(index, 1);
    },
    updateGuarantorField(e, index, prop) {
      //if its an action from the customers dropdown
      if (prop === "source") {
        const copyOfGuarantors = [...this.security];
        const actualObject = {
          savingsAccountKey: "",
          type: "GUARANTOR",
          guarantorKey: "",
          guarantorType: "GUARANTOR",
          amount: "",
          description: "",
          assetName: "",
          guarantyKey: "",
        };
        copyOfGuarantors[index][prop] = e;
        //this.security = copyOfGuarantors;
        copyOfGuarantors[index].actualObject = actualObject;
        copyOfGuarantors[index].actualObject.guarantorKey = e.customerKey;
        this.security = [...copyOfGuarantors];

        //search for the selected customer accounts
        if (e.hasOwnProperty("customerKey")) {
          if (e.customerKey.length) {
            copyOfGuarantors[index].loadingAccounts = true;
            DepositApiService.get(
              "DepositAccount/GetDepositAccountByCustomer/" +
                e.customerKey
            )
              .then((res) => {
                if (res) {
                  copyOfGuarantors[index].accountList = res.data.data;
                }
                copyOfGuarantors[index].loadingAccounts = false;
              })
              .catch(() => {
                copyOfGuarantors[index].loadingAccounts = false;
              });
          }
        }
      } else if (prop === "amount") {
        const copyOfGuarantors = [...this.security];
        copyOfGuarantors[index][prop] = e.target.value;
        copyOfGuarantors[index].actualObject.amount = e.target.value;
        copyOfGuarantors[index].actualObject.savingsAccountKey =
          copyOfGuarantors[index].account ||
          copyOfGuarantors[index].depositAccountKey;
        this.security = copyOfGuarantors;
      } else if (prop === "account") {
        const copyOfGuarantors = [...this.security];
        copyOfGuarantors[index].actualObject.savingsAccountKey =
          e.target.options[e.target.selectedIndex].value;
        copyOfGuarantors[index].actualObject.savingsAccountKey =
          copyOfGuarantors[index].account;
        copyOfGuarantors[index].actualObject.savingsAccountKey = "this";
        this.security = copyOfGuarantors;
      }
    },
    updatedGuarantorField(item, index) {
      //if its an action from the customers dropdown
      const copyOfGuarantors = [...this.security];
      const actualObject = {
        savingsAccountKey: "",
        type: "GUARANTOR",
        guarantorKey: "",
        guarantorType: "GUARANTOR",
        amount: "",
        description: "",
        assetName: "",
        guarantyKey: "",
      };
      // copyOfGuarantors[index].source = e.target.value.customerKey;
      //this.security = copyOfGuarantors;
      copyOfGuarantors[index].actualObject = actualObject;
      copyOfGuarantors[index].actualObject.guarantorKey = item;
      copyOfGuarantors[index].actualObject.amount =
        copyOfGuarantors[index].amount;
      this.security = [...copyOfGuarantors];

      //search for the selected customer accounts
      if (item.length) {
        copyOfGuarantors[index].loadingAccounts = true;
        DepositApiService.get(
          "DepositAccount/GetDepositAccountByCustomer/" +
            item
        )
          .then((res) => {
            if (res) {
              copyOfGuarantors[index].depositAccountByGuarantor = res.data.data;
            }
            copyOfGuarantors[index].loadingAccounts = false;
          })
          .catch(() => {
            copyOfGuarantors[index].loadingAccounts = false;
          });
      }
    },
    updateSavingsKey(index) {
      const copyOfGuarantors = [...this.security];

      copyOfGuarantors[index].actualObject.savingsAccountKey =
        copyOfGuarantors[index].depositAccountKey;
      this.security = [...copyOfGuarantors];
    },
    updateCollateralInputField(e, index, prop) {
      const actualObject = {
        savingsAccountKey: "",
        type: "ASSET",
        guarantorKey: "",
        guarantorType: "ASSET",
        amount: "",
        description: "",
        assetName: "",
        guarantyKey: "",
      };
      const copyOfCollateralAssets = [...this.collateralAsset];
      copyOfCollateralAssets[index][prop] = e.target.value;
      copyOfCollateralAssets[index].actualObject = actualObject;
      copyOfCollateralAssets[index].actualObject.assetName =
        copyOfCollateralAssets[index].assetName;
      copyOfCollateralAssets[index].actualObject.description =
        copyOfCollateralAssets[index].description;
      copyOfCollateralAssets[index].actualObject.amount =
        copyOfCollateralAssets[index].amount;
      this.collateralAsset = copyOfCollateralAssets;
    },
  },
  computed: {
    customerFullName() {
      // return (
      //   this.account.customerInformationDetails.data.customerFirstName +
      //   ` ` +
      //   this.account.customerInformationDetails.data.customerLastName
      // );
      return this.account.customerInformationDetails
      ? `${this.account.customerInformationDetails.data.customerFirstName} ${this.account.customerInformationDetails.data.customerLastName}`
      : "";
    },
    displayName() {
      return this.account.adminLoanProduct.product_name;
    },
    checkSecurityArray() {
      return this.account.security != null || this.account.security != "";
    },
    checkAssetsArray() {
      return this.account.assets != null || this.account.assets != "";
    },
  },
  mounted() {
    this.$watch("show", function (value) {
      if (value) {
        this.updateSecurity();
        this.getAllCustomers();
      }
    });
  },
};
</script>
