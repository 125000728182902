<template>
  <div
    class="tab-pane fade"
    id="pills-comments"
    role="tabpanel"
    aria-labelledby="pills-comments-tab"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Delete Comment
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <button
        class="button button--black d-flex align-items-center ml-auto"
        @click="toggleCommentModal"
        v-if="userCanCreateComment"
      >
        <span class="text--capital d-inline-flex mr-2 text-white text--small"
          >Add new Comments</span
        >
        <ion-icon
          name="add-outline"
          class="text--600 text--medium text-white"
        ></ion-icon>
      </button>
    </div>
    <div
      class="d-flex align-items-center justify-content-center p5"
      v-if="loading"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text--black text--600 text--medium d-inline-flex ml-4"
        >Please wait</span
      >
    </div>
    <div v-if="data && !loading">
      <div class="form__item" v-for="(item, i) in data" :key="i">
        <div class="row">
          <div class="col-3">
            <div class="row no-gutters">
              <div class="col-1">
                <img
                  src="../../../../../assets/img/edit-icon.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="col-11 px-5">
                <span class="d-block text--black text--regular text--600">{{
                  item.createdBy
                }}</span>
                <span class="text--tiny text--dark text-300 text--opaq1">
                  {{ item.creationDate | humanDate }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="pr-5">
              <p class="text--small">
                {{ item.commentNote }}
              </p>
            </div>
          </div>
          <div class="col-3 align-self-center">
            <div class="pl-5 d-flex align-items-center justify-content-end">
              <button
                type="button"
                @click="edit(item)"
                v-if="userCanEditComment"
                class="mx-2 button button--grey text--capital"
              >
                <span class="d-inline-flex">edit</span>
              </button>
              <button
                @click="confirmSave(item.commentKey)"
                v-if="userCanDeleteComment"
                type="button"
                class="mx-2 button button--cancel text--capital"
              >
                <span class="d-inline-flex">delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
import moment from "moment";
function initialState() {
  return {
    confirm: false,
    process: false,
    key: false,
  };
}
export default {
  name: "accountAttachmentsTab",
  data: function () {
    return initialState();
  },
  props: {
    data: Array,
    enums: Object,
    loading: Boolean,
    userCanCreateComment: Boolean,
    userCanEditComment: Boolean,
    userCanDeleteComment: Boolean,
  },
  methods: {
    toggleCommentModal() {
      this.$emit('toggle-comment-modal');
    },
    confirmSave(key) {
      this.key = key;
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    edit(d) {
      this.$emit("edit", d);
    },
    save: function () {
      this.process = true;
      const p = {
        commentKey: this.key,
      }
      LoanApiService.del(
        "LoanAccount/delete-loanaccount-comment", 
        p
      )
      .then(response => {
        this.confirm = false;
        this.process = false;
        this.$emit("refresh", this.$route.params.loanAccountKey);
        this.$emit("reload-activities");
        this.$emit("alertSuccess", response.data.message);
        this.$refs.close.click();
      })
      .catch(e => {
        this.confirm = false;
        this.process = false;
        this.$emit(
          "alertError",
          e.response.data.message
            ? e.response.data.message
            : "Unable to delete Comment currently"
        );
      })
    },
  },
  filters: {
    humanDate: function (date) {
      return date != null ? moment(date).fromNow() : "";
    },
  },
};
</script>
