<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="accruedInterestModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Apply Accrued Interest
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                @click="closeModal"
                ref="close"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Apply Accrued Interest
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Interest application Date</label>
                <input
                  type="date"
                  class="input form__input form__input--lg"
                  v-model="form.interestApplicationDate"
                />
                <div class="error--text" v-if="!uiProps.errors.date.valid">
                  {{ uiProps.errors.date.message }}
                </div>
              </div>
              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >notes</label
                  >
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder=""
                    v-model="form.notes"
                  ></textarea>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="button"
                    class="button form__button form__button--lg save-changes"
                    @click="confirmSave"
                  >
                    Apply Accrued Interest
                  </button>
                  <a
                    data-dismiss="#accruedInterestModal"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="closeModal"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>

    <!--RECONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: reconfirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="close()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Apply Fee
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                {{ canProceedMsg }}
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="close"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="confirmApply"
                >
                  {{ reconfirmation ? "Saving..." : "Ok" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
function initialState() {
  return {
    form: { notes: "", interestApplicationDate: "" },
    uiProps: {
      errors: {
        comment: { valid: true, message: "" },
        date: { valid: true, message: "" } 
      },
    },
    canProceedToApplyFees: false,
    canProceedMsg: '',
    reconfirm: false,
    reconfirmation: false,
    confirm: false,
    process: false,
  };
}
export default {
  name: "lockAccountModal",
  props: {
    account: Object,
    show: Boolean,
  },

  data: function () {
    return initialState();
  },

  methods: {
    confirmSave() {
      this.resetErrors();
      // eslint-disable-next-line no-constant-condition
      if (this.form.interestApplicationDate === "") {
        this.$emit("alertError", "Please select interest application date");
        return;
      }
      if (this.validate()) {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },

    close() {
      this.reconfirm = false;
      this.process = false;
      this.confirm = false;
    },

    save: function () {
      this.process = true;

      //check the interest cap charges before applying interest
      this.checkLoanCapCharges();
    },

    confirmApply() {
      this.reconfirmation = true;
      const p = {
        interestApplicationDate: this.form.interestApplicationDate,
        loanAccountKey: this.account.id,
        note: this.form.notes,
      };
      LoanApiService.patch(`LoanAccount/applyaccruedinteresttoloanaccount`, p)
      .then((res) => {
        this.confirm = false;
        this.process = false;
        if (res.status) {
          this.$emit("refresh");
          this.$emit("reload");
          this.$emit("alertSuccess", "Interest Applied Successfully");
          this.$refs.close.click();
        } else {
          this.$emit(
            "alertError",
            res.message ? res.message : "Unable to apply interest currently"
          );
        }
      })
      .catch((e) => {
        this.confirm = false;
        this.process = false;
        try {
          this.$emit(
            "alertError",
            e.response.data.message
              ? e.response.data.message
              : "Unable to apply interest currently"
          );
        } catch (e) {
          this.$emit(
            "alertError",
            "An unexpected Error occurred: Kindly confirm you are connected to the internet"
          );
        }
      });
    },

    checkLoanCapCharges() {
      const loanAccountKey = this.$route.params.loanAccountKey;

      const date = this.form.interestApplicationDate;

      LoanApiService.get(`LoanAccount/checkCapChargesBeforeFeeApply?&loanKey=${loanAccountKey}&type=interest&interestApplicationDate=${date}`)
      .then((resp) => {
        this.canProceedToApplyFees = resp.data.status;
        this.canProceedMsg = resp.data.message;
      })
    },

    validate: function () {
      // if (this.form.comment.length < 5) {
      //   this.uiProps.errors.comment.valid = false;
      //   this.uiProps.errors.comment.message =
      //     "Comment cannot be less than 5 characters";
      //   return false;
      // } else
      if (this.form.interestApplicationDate === ""){
        this.uiProps.errors.date.valid = false;
        this.uiProps.errors.date.message =
          "Please select date before proceeding";
        return false;
      } else {
        return true;
      }
    },
    closeModal() {
      this.$emit('close-apply-fee-modal');
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
  },

  watch: {
    "canProceedToApplyFees": function (newVal) {
      if (newVal) {
        return this.confirmApply();
      }
      this.reconfirm = true;
      this.process = false;
    }
  }
};
</script>
