<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="payOffModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Payoff
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                ref="close"
                class="modal__close modal__close--black"
                @click="closeModal"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Payoff Loan</h5>
          </div>
          <div class="modal__body">
            <p class="d-flex align-items-center">
              <span class="d-inline-block mr-2">
                <img src="assets/img/warning.svg" alt="" srcset="" />
              </span>
              <span class="d-inline-block text--small text--primary text--500">
                Remaining balance will be paid off, future Interest will be
                ignored and the account will be closed
              </span>
            </p>
            <form>
              <div class="row mt5">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label"
                      >PayOff Rate</label
                    >
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span
                          class="
                            w-100
                            d-flex
                            text--white text--600 text--medium
                            align-items-center
                            justify-content-center
                          "
                        >
                          %
                        </span>
                      </div>
                      <input
                        type="text"
                        class="input input--right form__input form__input--lg"
                        placeholder="PayOff Rate"
                        v-model.number="form.payOffRate"
                      />
                    </div>
                  </div>
                  <div class="error--text" v-if="!uiProps.errors.payOffRate.valid">
                    {{ uiProps.errors.payOffRate.message }}
                  </div>
                </div>

                <div class="col-4 mt-5">
                  <div class="form__item">
                    <div class="form__action">
                      <button
                        class="button form__button form__button--lg w-30 save-changes"
                        v-if="recalculate"
                      >
                        <div class="spinner-border texxt-light" role="status"></div>
                      </button>
                      <button
                        type="button"
                        v-else
                        class="button form__button form__button--lg w-30 save-changes"
                        @click.prevent="recalculatePayOff"
                      >
                        Calculate PayOff Amount
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt5">
                <div class="col-4">
                  <div class="form__item">
                    <span
                      class="text--500 text--regular text--capital d-block mb-3"
                      >Principal Balance</span
                    >
                    <span class="text--bold text--medium text--capital d-block"
                      >{{ account.balances.principalBalance | currency }}</span
                    >
                  </div>
                </div>
                <div class="col-4">
                  <div class="form__item">
                    <span
                      class="text--500 text--regular text--capital d-block mb-3"
                      >Payoff Amount</span
                    >
                    <span class="text--bold text--medium text--capital d-block"
                      >{{ form.payOffAmount | currency }}</span
                    >
                  </div>
                  <div class="error--text" v-if="!uiProps.errors.payOffAmount.valid">
                    {{ uiProps.errors.payOffAmount.message }}
                  </div>
                </div>
              </div>

              <div class="form__item">
                <div class="form__item">
                  <label
                    class="form__label text--regular-alt text--bold text--black"
                    >notes</label
                  >
                  <div class="error--text" v-if="!uiProps.errors.notes.valid">
                    {{ uiProps.errors.notes.message }}
                  </div>
                  <textarea
                    cols="5"
                    rows="3"
                    class="input form__input"
                    placeholder=""
                    :class="{ 'input-error': !uiProps.errors.notes.valid }"
                    v-model="form.notes"
                  ></textarea>
                </div>
              </div>

              <div class="form__item">
                <div class="form__action">
                  <button
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="userCanClosePayOff"
                    @click="confirmSave"
                  >
                    pay off
                  </button>
                  <a
                    data-dismiss="#payOffModal"
                    role="button"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="closeModal"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
function initialState() {
  return {
    form: { notes: "", payOffRate: '', payOffAmount: '' },
    payOffRate: '',
    uiProps: {
      errors: {
        notes: { valid: true, message: "" },
        payOffRate: { valid: true, message: "" },
        payOffAmount: { valid: true, message: "" },
      },
    },
    confirm: false,
    process: false,
    recalculate: false,
  };
}
export default {
  name: "closePayoffModal",
  props: {
    account: Object,
    show: Boolean,
    userCanClosePayOff: Boolean,

  },
  data: function () {
    return initialState();
  },
  methods: {
    closeModal() {
      this.$emit('close-payoff');
      this.$emit("refresh");
    },
    resetWindow: function (){
      Object.assign(this.$data, initialState());
    },
    confirmSave() {
      this.resetErrors();
      // eslint-disable-next-line no-constant-condition
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    recalculatePayOff() {
      this.recalculate = true;
      LoanApiService.get(`LoanAccount/getpayoffamount/${this.account.id}?PayOffRate=${this.form.payOffRate}`)
      .then((res) => {
        this.recalculate = false;
        this.form.payOffRate = res.data.data.payOffRate;
        this.form.payOffAmount = res.data.data.payOffAmount;
        this.$emit(
          "alertSucess",
          'New payoff rate applied'
        );
      })
      .catch((e) => {
        this.recalculate = false;
        const error = `Pay Off Rate' must be greater than '0'`;
        try {
          this.$emit(
            "alertError",
            e.response.data.message
              ? e.response.data.message
              : error
          );
        } catch (e) {
          this.$emit(
            "alertError",
            error
          );
        }
      });
    },
    save: function () {
      this.process = true;
      const self = this;
      LoanApiService.post(`LoanAccount/loanaccountclosepayoff/`, {
        loanAccountId: self.account.id, ...this.form
      })
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("get-schedule");
            this.$emit("alertSuccess", res.data.message);
            this.$refs.close.click();
            this.resetWindow();
          } else {
            this.$emit(
              "alertError",
              res.message ? res.message : "Unable to perform action currently"
            );
          }
        })
        .catch((e) => {
          this.confirm = false;
          this.process = false;
          try {
            this.$emit(
              "alertError",
              e.response.data.message
                ? e.response.data.message
                : "Unable to perform action currently"
            );
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    validate: function () {
      if (this.form.notes.length < 5) {
        this.uiProps.errors.notes.valid = false;
        this.uiProps.errors.notes.message =
          "Note cannot be less than 5 characters";
        return false;
      } 
      if (this.form.payOffRate === '') {
        this.uiProps.errors.payOffRate.valid = false;
        this.uiProps.errors.payOffRate.message = 'Pay Off Rate is Required';
        return false;
      }
      if (this.form.payOffAmount === '') {
        this.uiProps.errors.payOffRate.valid = false;
        this.uiProps.errors.payOffRate.message = 'Pay Off Amount is Required';
        return false;
      }
      else {
        return true;
      };
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
    resetPayOff() {
      this.form.payOffRate = this.account.payOffRate ? this.account.payOffRate : 0;
      this.form.payOffAmount = this.account.payOffAmount ? this.account.payOffAmount : 0;
    }
  },
  mounted() {
    this.$watch("account", function (value) {
      if (value) this.resetPayOff();
    });
  },
  // watch: {
  //   account(change) {
  //     if (change) {
  //       this.resetPayOff();
  //     }
  //   }
  // }
};
</script>
