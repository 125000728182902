<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="writeOffModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Close Write Off
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#writeOffModal"
                class="modal__close modal__close--black"
                @click="closeModal"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Write Off Loan Account
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="mt-3">
                <div class="row py-3 align-items-center">
                  <div class="col-12">
                    <span
                      class="
                        text--black text--regular
                        d-block
                        text--500
                        mb-3
                        text--capital
                      "
                    >
                      Account Recipient
                    </span>
                    <span
                      class="
                        text--black text--medium
                        d-block
                        text--600 text--capital
                      "
                    >
                      {{ accountFullName }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="row py-3 align-items-center">
                  <div class="col-12">
                    <span
                      class="
                        text--black text--regular
                        d-block
                        text--500
                        mb-3
                        text--capital
                      "
                    >
                      Loan Account
                    </span>
                    <span
                      class="
                        text--black text--medium
                        d-block
                        text--600 text--capital
                      "
                    >
                      {{ uiProps.loanName }}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div class="mt5">
                <h6 class="text--600 text--capital text--regular">
                  Outstanding Balance
                </h6>
                <div class="row py-4 no-gutters border-bottom align-items-center">
                  <div class="col-6">
                    <span
                      class="
                        text--black text--small
                        d-block
                        text--500 text--capital
                      "
                    >
                      Total
                    </span>
                    <span
                      class="
                        text--black text--regular
                        d-block
                        text--700 text--capital
                      "
                    >
                      {{ uiProps.total | currency }}
                    </span>
                  </div>
                  <div class="col-6">
                    <span
                      class="
                        text--black text--small
                        d-block
                        text--500 text--capital
                      "
                    >
                      Principal
                    </span>
                    <span
                      class="
                        text--black text--regular
                        d-block
                        text--700 text--capital
                      "
                    >
                      {{ uiProps.principal | currency }}
                    </span>
                  </div>
                </div>
                <div class="row py-4 no-gutters border-bottom align-items-center">
                  <div class="col-6">
                    <span
                      class="
                        text--black text--small
                        d-block
                        text--500 text--capital
                      "
                    >
                      Interest
                    </span>
                    <span
                      class="
                        text--black text--regular
                        d-block
                        text--700 text--capital
                      "
                    >
                      {{ uiProps.interest | currency }}
                    </span>
                  </div>
                  <div class="col-6">
                    <span
                      class="
                        text--black text--small
                        d-block
                        text--500 text--capital
                      "
                    >
                      Fees
                    </span>
                    <span
                      class="
                        text--black text--regular
                        d-block
                        text--700 text--capital
                      "
                    >
                      {{ uiProps.fees | currency }}
                    </span>
                  </div>
                </div>
                <div class="row py-4 no-gutters align-items-center">
                  <div class="col-6">
                    <span
                      class="
                        text--black text--small
                        d-block
                        text--500 text--capital
                      "
                    >
                      Penalty
                    </span>
                    <span
                      class="
                        text--black text--regular
                        d-block
                        text--700 text--capital
                      "
                    >
                      {{ uiProps.penalty | currency }}
                    </span>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="row py-3 align-items-center">
                    <div class="col-12">
                      <span
                        class="
                          text--black text--regular
                          d-block
                          text--500
                          mb-3
                          text--capital
                        "
                      >
                        Write Off Amount
                      </span>
                      <span
                        class="
                          text--black text--medium
                          d-block
                          text--600 text--capital
                        "
                      >
                        {{ uiProps.total | currency }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!--ACCOUNT NOTES-->
              <div class="modal__accordion mb-5">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#decriptions"
                  aria-expanded="false"
                  aria-controls="decriptions"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    Notes
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="decriptions" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <textarea
                        cols="5"
                        rows="5"
                        class="input form__input"
                        v-model="form.notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__item mt-5 pt-5">
                <div class="form__action">
                  <button
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                    @click="confirmSave()"
                  >
                    {{ process ? "Processing..." : "Write Off" }}
                  </button>
                  <a
                    role="button"
                    data-dismiss="#writeOffModal"
                    class="
                      modal__close
                      form__action__text form__action__text--danger
                      text--capital
                      ml6
                    "
                    @click="closeModal"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import LoanApiService from "@/core/services/api.loan.service";
export default {
  name: "closeWriteOff",
  data: function () {
    return {
      uiProps: {
        loanName: null,
        errors: {
          interestBalance: { valid: true, message: "" },
          feeBalance: { valid: true, message: "" },
          penaltyBalance: { valid: true, message: "" },
        },
        fees: 0,
        principal: 0,
        interest: 0,
        penalty: 0,
        total: 0,
      },
      form: {
        loanAccountId: null,
        notes: "",
      },
      confirm: false,
      process: false,
    };
  },
  props: {
    account: Object,
    show: Boolean,
    customer: {
      required: false,
      type: Object,
      default: null,
    },
  },
  watch: {
    account(change) {
      if (change) {
        if (change.balances) {
          this.uiProps.loanName = change.loanName;
          this.form.loanAccountId = change.id;
          let fees = 0;
          let principal = 0;
          let interest = 0;
          let penalty = 0;
          let total = 0;
          Object.entries(change.balances).forEach(([key, value]) => {
            if (key.indexOf("feesBalance") !== -1) {
              fees += value;
              total += value;
            } else if (key.indexOf("principalBalance") !== -1) {
              principal += value;
              total += value;
            } else if (key.indexOf("interestBalance") !== -1) {
              interest += value;
              total += value;
            } else if (key.indexOf("penaltyBalance") !== -1) {
              penalty += value;
              total += value;
            }
          });
          this.uiProps.fees = fees;
          this.uiProps.principal = principal;
          this.uiProps.interest = interest;
          this.uiProps.penalty = penalty;
          this.uiProps.total = total;
        }
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
        this.process = false;
      }
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    save: function () {
      this.process = true;
      LoanApiService.post("LoanAccount/loanaccountclosewriteoff", this.form)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("refresh");
            this.$emit("get-transactions");
            this.$emit("alertSuccess", res.message);
            this.closeModal();
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
  },
  computed: {
    accountFullName: function () {
        const { accountHolderType } = this.account;
        if (accountHolderType && accountHolderType.toLowerCase() == "groups")
          return this.account && this.account.customerInformationDetails
          ? this.account.customerInformationDetails.customerFirstName : "";

        return this.account && this.account.customerInformationDetails.data
        ? `${this.account.customerInformationDetails.data.customerFirstName} 
            ${this.account.customerInformationDetails.data.customerLastName}`
        : ""
      },
  }
};
</script>
